<script setup>
import { RouterLink } from "vue-router";

const links = [
  {
    label: "Privacy Policy",
    url: { name: "privacy-policy" },
  },
  {
    label: "Terms Of Use",
    url: { name: "terms-of-use" },
  },
  {
    label: "EULA",
    url: { name: "eula" },
  },
];
</script>

<template>
  <footer class="footer">
    <div class="footer__content">
      <RouterLink to="/" style="width: fit-content">
        <img
          class="footer__logo"
          src="@/assets/logo.svg"
          width="174"
          height="40"
          loading="lazy"
          alt="Appio Games logo"
          decoding="async"
        />
      </RouterLink>
      <ul class="footer__links">
        <li class="link" v-for="(link, i) in links" :key="i">
          <RouterLink class="link__text" :to="link.url">{{
            link.label
          }}</RouterLink>
        </li>
      </ul>
      <p class="footer__text">
        © 2023 Appio Oyun Yazilim Ve Reklam Anonim Sirketi
      </p>
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  padding: 3rem 1.6rem 4rem;
  background-color: #0d0d0d;

  &__content {
    color: white;
    text-align: left;
    max-width: 114.6rem;
    margin-left: auto;
    margin-right: auto;

    @include tablet() {
      display: grid;
      grid-template-areas:
        "A B"
        "C B";
    }
  }

  &__logo {
    width: 100%;
    max-width: 17.4rem;
    height: auto;

    @include tablet() {
      grid-area: A;
    }

    @include desktop() {
      width: 30.5rem;
      height: 7rem;
      max-width: none;
    }
  }

  &__links {
    list-style-type: none;
    padding: 0;
    margin: 4rem 0 0;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    @include tablet() {
      grid-area: B;
      justify-self: end;
      margin-top: 0;
    }

    .link {
      &__text {
        color: white;
        font-size: 1.8rem;
        line-height: 140%;
        font-weight: 800;
        text-decoration: none;

        @include desktop() {
          font-size: 2rem;
        }
      }
    }
  }

  &__text {
    margin-top: 4rem;
    font-size: 1.2rem;
    line-height: 140%;
    font-weight: 500;

    @include tablet() {
      grid-area: C;
      margin-top: 0;
      align-self: end;
    }

    @include desktop() {
      font-size: 1.4rem;
    }
  }
}
</style>
