<script setup>
import { ref } from "vue";

const mobileNavIsOpened = ref(false);
const navLinks = [
  {
    url: "/#about",
    label: "About",
  },
  {
    url: "/#projects",
    label: "Projects",
  },
  {
    url: "/#features",
    label: "Features",
  },
  {
    url: "/#team",
    label: "Join us",
  },
  {
    url: "/#get-in-touch",
    label: "Contact us",
  },
];

function openNavBar() {
  document.body.classList.add("no-scroll");
  mobileNavIsOpened.value = true;
}

function closeNavBar() {
  document.body.classList.remove("no-scroll");
  mobileNavIsOpened.value = false;
}
</script>

<template>
  <nav class="nav-container">
    <div class="content">
      <RouterLink class="logo" to="/">
        <img
          src="@/assets/logo.svg"
          alt="Application logo"
          width="140"
          height="32"
        />
      </RouterLink>

      <div class="navigation-body">
        <div
          class="burger"
          :class="{ open: mobileNavIsOpened }"
          data-burger-click
          @click="mobileNavIsOpened ? closeNavBar() : openNavBar()"
        >
          <span class="burger-line top" data-burger-click></span>
          <span class="burger-line mid" data-burger-click></span>
          <span class="burger-line bot" data-burger-click></span>
        </div>
        <ul class="nav-links" :class="{ show: mobileNavIsOpened }">
          <li v-for="item in navLinks" :key="item.id" @click="closeNavBar">
            <a class="link-item" :href="item.url">
              {{ item.label }}
            </a>
          </li>
        </ul>
        <div
          class="overlay"
          :class="{ show: mobileNavIsOpened }"
          @click="closeNavBar"
        ></div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.nav-container {
  position: sticky;
  z-index: 10;
  top: 0;
  padding: 3rem 1.6rem;
  background-image: url("@/assets/home-header/top-shape.svg");
  background-repeat: no-repeat;
  background-size: 52.5rem 52.5rem;
  background-position: top -10.7rem left -23rem;
  background-color: #0d0d0d;

  @include desktop() {
    background-size: 73.8rem 73.8rem;
    background-position: top -13.3rem left -21.3rem;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 114.6rem;
    margin: 0 auto;
  }

  .logo {
    display: flex;
    align-items: center;

    img {
      width: auto;
      height: 3.2rem;
    }

    .app-name {
      margin-left: 1rem;
    }
  }

  .burger {
    width: 5rem;
    height: 2.2rem;
    position: relative;
    cursor: pointer;
    z-index: 3;

    .burger-line {
      width: 100%;
      border-radius: 0.1rem;
      border: 0.1rem solid white;
      position: absolute;
      left: 0;
    }

    .top {
      top: 0;
      transition: transform 0.1s linear, top 0.1s 0.1s linear;
    }

    .mid {
      top: calc(50% - 0.1rem);
      opacity: 100;
      transition: opacity 0s 0.1s;
    }

    .bot {
      bottom: 0;
      transition: bottom 0.1s 0.1s linear, transform 0.1s linear;
    }
  }

  .open {
    .top {
      top: calc(50% - 0.1rem);
      transform: rotate(-45deg);
      transition: transform 0.1s 0.1s linear, top 0.1s linear;
    }

    .mid {
      opacity: 0;
    }

    .bot {
      bottom: calc(50% - 0.1rem);
      transform: rotate(45deg);
      transition: bottom 0.1s linear, transform 0.1s 0.1s linear;
    }
  }

  .nav-links {
    display: grid;
    align-content: start;
    overflow-y: auto;
    gap: 4rem;
    list-style: none;
    padding: 12rem 2.4rem;
    margin: 0;
    position: fixed;
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    background-color: #0d0d0d;
    z-index: 2;
    transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    .link-item {
      color: white;
      font-size: 1.6rem;
      line-height: 150%;
      font-weight: 500;
      text-decoration: none;

      @include desktop() {
        font-size: 1.8rem;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    background-color: #0d0d0d;
    opacity: 0.5;
    display: none;
    z-index: 1;
  }

  .show {
    left: 0;
  }
}

@include phone-md() {
  .nav-container {
    .logo {
      img {
        height: 3.6rem;
      }
    }

    .nav-links {
      gap: 5.2rem;
      padding: 14rem 2.4rem;
    }
  }
}

@include tablet() {
  .nav-container {
    padding: 4rem 2rem;

    .logo {
      height: 3.8rem;
    }

    .nav-links {
      gap: 5.6rem;
      max-width: 38.4rem;

      .link-item {
        font-size: 3.2rem;
        line-height: 3.8rem;
      }
    }

    .overlay {
      display: block;
    }

    .nav-links.show {
      left: calc(100vw - 38.4rem);
    }
  }
}

@include desktop() {
  .nav-container {
    padding: 3rem 14.7rem;

    .logo {
      height: 4rem;
    }

    .burger {
      display: none;
    }

    .nav-links {
      position: static;
      grid-auto-flow: column;
      gap: 4rem;
      transition: none;
      padding: 0;
      max-width: none;
      overflow-y: visible;

      .link-item {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.9rem;
      }
    }

    .overlay {
      display: none;
    }
  }
}
</style>
